
.main-wrapper {
    height: 100%;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
}
.goods-wrapper {
    display: flex;
    align-items: center;
    .goods-cover {
        width: 80px;
        height: 80px;
        display: flex;
        background: #F6F6F6;
        img  {
            max-width: 100%;
            max-height: 100%;
            margin: auto;
        }
    }
    .goods-name {
        width: 1%;
        flex: 1;
        margin-left: 10px;
        font-size: 16px;
    }
}
